<template>
  <div class="modal ready-modal">
    <div class="overlay"  @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReadyModal" src="./../img/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title small">{{$t('Purchase product?')}}</div>
              <div class="buttons">
                <button class="button" @click="$parent.closeReadyModal">
                  <span>{{$t('Cancel')}}</span>
                </button>
                <button :class="['button blue', {'disabled': $parent.addToCartBtnLoader}]" @click="addToCart">
                  <span>{{$t('Yes')}}</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$t($parent.error)}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		addToCart() {
			this.$emit('addToCart')
		}
  }
}
</script>
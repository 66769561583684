<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="../img/logo.svg" class="img"/>
          </router-link>
          <div class="text">
            <div class="desc">{{ $t('This website is operated by') }} {{ $parent.settings.company_name }}</div>
            <div class="desc">{{ $t('Registry code') }}: {{ $parent.settings.company_reg_number }}</div>
            <div class="desc">{{ $t('Legal address') }}: <span>{{ $parent.settings.company_address }}</span></div>
          </div>
        </div>
        <div class="center">
          <div class="title medium">{{ $t('Need help?') }}</div>
          <div class="desc">{{ $t('Contact us:') }}</div>
          <div class="link desc">{{ $parent.settings.support_email }}</div>
          <ul>
            <li v-for="(item, i) in socials" :key="i">
              <a :href="item.url" target="_blank">
                <img :src="$parent.imgDomain + item.image" class="img"/>
              </a>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul class="nav" v-if="$parent.textPageList.length">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item nav__item-text-pages" :key="item.id">
                <div @click="$parent.goToPage(item.id)" class="desc">
                  {{ item.title }}
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="section copyright-section">
      <div class="wrapper">
        <div class="desc">{{ $parent.settings.copyright }}</div>
        <ul class="list">
          <li class="item">
            <img src="./../img/mc_symbol.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../img/visa.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../img/3ds.png" class="img"/>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      socials: []
		}
	},
	methods: {
		scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    }
	},
	mounted() {
    this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socials = res.data.payload
      })
      .catch(() => {
        
      })
  }
}
</script>

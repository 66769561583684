<template>
  <div class="menu">
    <ul class="nav">
      <li class="nav__item">
        <router-link class="desc" to="/product-list/images">{{ $t('Images') }}</router-link>
      </li>
      <li class="nav__item">
        <router-link class="desc" to="/product-list/texts">{{ $t('Texts') }}</router-link>
      </li>
    </ul>
    <div class="header-profile header-profile-mob" v-if="$parent.isAuth">
      <router-link class="profile-desc" to="/profile/main">
        <div class="icon button">
          <img class="img" src="../img/user.svg" alt="">/>
        </div>
        <div class="desc name">{{ $t('My Profile') }}</div>
      </router-link>
      <router-link to="/cart" class="button cart blue">
        <img class="img" src="../img/cart.svg"/>
      </router-link>
    </div>
    <div v-else>
      <div class="button modal-open" @click="$emit('openSignInModal')">
        <span>{{ $t('Login') }}</span>
      </div>
      <div class="button blue" @click="$emit('openSignUpModal')">
        <span>{{ $t('Sign Up') }}</span>
      </div>
    </div>
    <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
      <select class="select currency-select" v-model="curr" @change="selectCurr()">
        <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
      </select>
    </div>
  </div>
</template>


<script>

export default {
	name: 'Menu',
	props: ['currencyCode', 'currencyValue'],
	data: function() {
		return {
			curr: ''
		}
	},
	computed: {
		currOptions() {
			return this.$parent.currencyOptions.map(({code}) => code);
		}
	},
	watch: {
		currencyCode: function (newValue) {
			this.curr = newValue
		}
	},
	mounted() {
		this.curr = this.currencyCode
	},
	methods: {
		openSignInModal() {
			this.$emit('openSignInModal')
		},
		openSignUpModal() {
			this.$emit('openSignUpModal')
		},
		scrollToSection(section) {
			this.$parent.closeMenu();
			this.$emit('scrollToSection', section)
		},
		selectCurr() {
			this.changeCurrency(this.curr)
			localStorage.setItem("currency", this.curr);
		},
		changeCurrency(item) {
			this.$emit('changeCurrency', item)
		},
	},
}
</script>

<template>
  <div class="modal filter-modal">
    <div class="overlay" @click="$parent.closeFilterModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeFilterModal" src="./../img/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="top">
                <div class="title small">{{ $t('Filters') }}</div>
              </div>
              <div class="bottom">
                <div class="filter-tabs">
                  <div @click="filterTab = 'categories'" :class="{'blue active': filterTab === 'categories'}" class="button">{{ $t('Categories') }}</div>
                  <div @click="filterTab = 'tags'" :class="{'blue active': filterTab === 'tags'}" class="button">{{ $t('Tags') }}</div>
                </div>
                <div class="input-container">
                  <input type="text" :placeholder="$t('Enter request')" v-model="search"/>
                  <button class="button blue" @click="filter">
                    <img src='./../img/glass.svg' class="img"/>
                  </button>
                </div>
                <template v-if="filterTab === 'categories'" >
                  <div v-if="activeCategories && activeCategories.length > 0" class="active-list">
                    <button v-for="item in activeCategories" @click="removeFilter('category', item)" class="button blue"
                            :key="item.id">
                      {{ item.title }}
                      <img src='./../img/cross.svg' class="img"/>
                    </button>
                  </div>
                  <div class="list" v-if="$parent.appActiveType == 'Texts'">
                    <button v-for="item in filteredDocCategories" @click="addFilter('category', item)" class="button" :key="item.id">
                      {{ item.title }}
                    </button>
                  </div>
                  <div class="list" v-else>
                    <button v-for="item in filteredImgCategories" @click="addFilter('category', item)" class="button" :key="item.id">
                      {{ item.title }}
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div v-if="activeTags && activeTags.length > 0" class="active-list">
                    <button v-for="item in activeTags" @click="removeFilter('tag', item)" class="button blue"
                            :key="item.id">
                      {{ item.name }}
                      <img src='./../img/cross.svg' class="img"/>
                    </button>
                  </div>
                  <div class="list">
                    <button v-for="item in filteredTags" @click="addFilter('tag', item)" class="button" :key="item.id">
                      {{ item.name }}
                    </button>
                  </div>
                </template>
              </div>
              <div class="buttons">
                <button class="button" @click="$parent.closeFilterModal">
                  <span>{{ $t('Cancel') }}</span>
                </button>
                <button class="button blue" @click="applyFilters">
                  <span>{{ $t('Apply') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      filterTab: 'categories',
      activeCategories: [],
      categoryOptions: [],
      search: '',
      filteredCategories: [],
      filteredDocCategories: [],
      filteredImgCategories: [],
      activeTags: [],
      tagOptions: [],
      filteredTags: []
    }
  },
  mounted() {
    this.getCategories();
    this.getTags();
  },
  methods: {
    filter() {
      const searchTerm = this.search.toLowerCase();

      if (this.filterTab === 'categories') {
        this.filteredCategories = this.categoryOptions.filter(item => item.title.toLowerCase().includes(searchTerm));
      } else {
        this.filteredTags = this.tagOptions.filter(item => item.name.toLowerCase().includes(searchTerm));
      }
    },
    addFilter (type, item) {
      let filters;
      let itemKeyField;

      if (type === 'category') {
        filters = this.activeCategories;
        itemKeyField = 'title';
      } else {
        filters = this.activeTags;
        itemKeyField = 'name';
      }

      const itemToAdd = item
      const exists = filters.find(item => item[itemKeyField] === itemToAdd[itemKeyField]);
      if (!exists) {
        filters.push(item);
      }
    },
    removeFilter (type, item) {
      let filters;
      let itemKeyField;

      if (type === 'category') {
        filters = this.activeCategories;
        itemKeyField = 'title';
      } else {
        filters = this.activeTags;
        itemKeyField = 'name';
      }

      const itemToRemove = item;
      const indexToRemove = filters.findIndex(item => item[itemKeyField] === itemToRemove[itemKeyField]);

      if (indexToRemove !== -1) {
        filters.splice(indexToRemove, 1);
      }
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
        let types = res.data.payload.filter(item => item.parent_id === null);
        if (this.$parent.appActiveType === 'Texts') {
          this.filteredDocCategories = this.categoryOptions.filter(item => item.parent_id === types[1].id);
        } else {
          this.filteredImgCategories = this.categoryOptions.filter(item => item.parent_id === types[0].id);
        }
        
        if (this.$route.query['filter[category]']) {
          const categoryIds = this.$route.query['filter[category]'].split(',').map((id) => parseInt(id));
          this.activeCategories = this.categoryOptions.filter(({id}) => categoryIds.includes(id));
        }
      })
      .catch(() => {
         
      })
    },
    getTags() {
      this.$http.get(process.env.VUE_APP_API + 'tags')
          .then((res) => {
            this.tagOptions = res.data.payload;
            this.filteredTags = this.tagOptions;

            if (this.$route.query['filter[tag]']) {
              const tags = this.$route.query['filter[tag]'].split(',');
              this.activeTags = this.tagOptions.filter(({name}) => tags.includes(name));
            }
          })
          .catch(() => {

          })
    },
    updateUrlFilters() {
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = { ...currentQuery, 'filter[category]': this.activeCategories.map(({id}) => id).join(','), 'filter[tag]': this.activeTags.map(({name}) => name).join(',') };
      this.$router.replace({ path: currentPath, query: newQuery });
    },
    applyFilters() {
      this.$emit('applyFilters', this.activeCategories, this.activeTags);
      this.updateUrlFilters();
      this.$parent.closeFilterModal();
    }
  }
}
</script>
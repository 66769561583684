<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <!-- <div class="title">
                  My Profile  -->
                  <!--<span v-if="verification_data.status !== 'approved'" v-tooltip="{
                    content: 'Please verify your profile to withdraw funds',
                    placement: 'right',
                  }">
                    <img class="img shield" src="./../img/shield.svg"/>
                  </span>
                  <span v-if="verification_data.status === 'approved'" v-tooltip="{
                    content: 'Your profile is verified',
                    placement: 'right',
                  }">
                    <img class="img shield" src="./../img/verify.svg"/>
                  </span>-->
                <!-- </div> -->
                <div class="big-left">
                  <div class="top-wrapper">
                    <div class="left">
                      <img class="avatar img" src="./../img/avatar.svg"/>
                      <div class="text" v-if="!profileIsEditable">
                        <div class="desc">{{ name }} {{ surname }}
                          <span v-if="verification_data.status !== 'approved'" v-tooltip="{
            content: $t('Please verify your profile'),
            placement: 'right',
          }">
            <router-link to="/verification"><img class="img shield" src="./../img/shield.svg"/></router-link>
          </span>
                          <span v-if="verification_data.status === 'approved'" v-tooltip="{
            content: $t('Your profile is verified'),
            placement: 'right',
            classes: verification_data.status === 'approved' ? 'verified' : ''
          }">
            <img class="img shield" src="./../img/verify.svg"/>
          </span>
                        </div>
                        <div class="desc">{{ email }}</div>
                        <div class="desc">{{ phone }}</div>
                      </div>
                      <div class="text text-edit" v-else>
                        <div class="desc">
                          <input type="text" :placeholder="$t('Name')" v-model="name"/>
                          <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
                        </div>
                        <div class="desc">
                          <input type="email" :placeholder="$t('Email')" v-model="email"/>
                        </div>
                        <div class="desc">
                          <input type="number" :placeholder="$t('Phone')" v-model="phone"/>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <!--<router-link to="/verification" class="button">Verification</router-link>-->
                      <button class="button blue button-edit" @click="editProfile()">
                        <span v-if="!profileIsEditable">{{$t('Edit profile')}}</span>
                        <span v-else>{{$t('Save')}}</span>
                      </button>
                      <button class="button" @click="$parent.logout">{{$t('Log Out')}}</button>
                    </div>
                    <transition name="fade">
                      <div class="desc red" v-if="profileUpdateErrorMessaage">{{$t(profileUpdateErrorMessaage)}}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="profileUpdateSuccessMessaage">{{$t(profileUpdateSuccessMessaage)}}</div>
                    </transition>
                  </div>
                  <div class="balance-block">
                    <div class="balance-block-wrapper">
                      <img src='./../img/wallet.svg' class="img"/>
                      <div class="desc">{{$t('Current balance')}} :</div>
                      <div class="title small">{{ balance }} {{ $parent.currency }}</div>
                      <button class="button blue" @click="$parent.openTopUpModal">{{$t('Top Up')}}</button>
                    </div>
                  </div>
                </div>

                <div class="big-right">
                  <ul class="nav">
                    <template v-for="(item, i) in profileNav">
                      <li :class="['item', {'active': item.page === activeProfilePage}]" @click="goToProfilePage(item.page)" :key="i">
                        <div class="desc">{{$t(item.title)}}</div>
                      </li>
                    </template>
                  </ul>
                  <div class="content" v-if="activeProfilePage === 'my-poducts'">
                    <div class="tab-list">
                      <select v-model="activeMyProductsTab">
                        <option v-for="(item,i) in myProductsTabs" :value="item.title" :key="i">{{$t(item.title)}}</option>
                      </select>
                    </div>
                    <button class="button blue" @click="$parent.goToPromtCreate">
                      {{$t('Create new')}}
                    </button>
                    <!-- <div class="title">{{$t(createdHistory)}}</div>
                    <div class="title">{{$t(createdHistoryImage)}}</div> -->
                    <div class="content-wrapper">
                      <div class="table-container table-container-depositHistory table-container-createdHistory" v-if="activeMyProductsTab === 'Art'">
                        <div class="table">
                          <table>
                            <thead>
                            <tr v-if="createdHistoryImage && createdHistoryImage.length">
                              <td>
                                <div class="td-wrapper">{{$t('Design')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Name')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Fiters')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Price')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Status')}}</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                            </thead>
                            <tbody v-if="createdHistoryImage && !createdHistoryImage.length">
                            <tr>
                              <td class="bold">{{$t('Empty')}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="createdHistoryImage && createdHistoryImage.length">
                            <tr v-for="(item,i) in createdHistoryImage" :key="i">
                              <td><div class="td-wrapper">
                                <div class="img-container">
                                  <img v-if="item.doc_type == 'image'" class="img" :src="$parent.imgDomain + item.doc_url" />
                                </div>
                              </div></td>
                              <td>
                                <div class="td-wrapper">
                                  {{item.title}}
                                </div>
                              </td>
                              <td><div class="td-wrapper" v-if="item.category_id && categoryOptions && categoryOptions.length">
                                <span>{{$t(categoryOptions.find(obj => obj.id === item.category_id).title)}}</span>
                              </div></td>
                              <td class="price"><div class="td-wrapper price">{{ item.user_price }} {{$parent.currency}}</div></td>
                              <td>
                                <div class="td-wrapper offer-link" v-if="item.status == 'offer'" @click="openOffer(item)">{{$t('Offer')}}</div>
                                <div class="td-wrapper" v-else>
                                  <span class="status">{{ item.status }}</span>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="table-container table-container-depositHistory table-container-createdHistory" v-if="activeMyProductsTab === 'Text'">
                        <div class="table">
                          <table>
                            <thead>
                            <tr v-if="createdHistoryText && createdHistoryText.length">
                              <td>
                                <div class="td-wrapper">{{$t('Design')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Name')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Description')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Fiters')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Price')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Status')}}</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                            </thead>
                            <tbody v-if="createdHistoryText && !createdHistoryText.length">
                            <tr>
                              <td class="bold">{{$t('Empty')}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="createdHistoryText && createdHistoryText.length">
                            <tr v-for="(item,i) in createdHistoryText" :key="i">
                              <td>
                                <div class="td-wrapper">
                                  <div v-if="item.doc_type == 'doc'" class="doc-img"><img src='./../img/doc.svg' class="image"/></div>
                                </div>
                              </td>
                              <td><div class="td-wrapper">
                                {{item.title}}
                              </div></td>
                              <td>
                                <div class="td-wrapper">
                                  {{item.description}}
                                </div>
                              </td>
                              <td><div class="td-wrapper" v-if="item.category_id && categoryOptions && categoryOptions.length">
                                <span>{{$t(categoryOptions.find(obj => obj.id === item.category_id).title)}}</span>
                              </div></td>
                              <td class="price"><div class="td-wrapper price">{{ item.user_price }} {{$parent.currency}}</div></td>
                              <td>
                                <div class="td-wrapper offer-link" v-if="item.status == 'offer'" @click="openOffer(item)">{{$t('Offer')}}</div>
                                <div class="td-wrapper" v-else>
                                  <span class="status">{{ item.status }}</span>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'purchased-poducts'">
                    <div class="content-wrapper">
                      <div class="table-container table-container-depositHistory table-container-createdHistory">
                        <div class="table">
                          <table>
                            <thead>
                            <tr v-if="$parent.orderHistory && $parent.orderHistory.length">
                              <td>
                                <div class="td-wrapper">{{$t('Purchase')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Name')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Fiters')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Price')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper"></div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                            </thead>
                            <tbody v-if="$parent.orderHistory && !$parent.orderHistory.length">
                            <tr>
                              <td class="bold">{{$t('Unfortunately, you don\'t have a purchase history yet.')}}</td>
                            </tr>
                            </tbody>
                            <tbody v-for="(item, i) in $parent.orderHistory" :key="i">
                            <tr v-for="(itemPart, i) in item.items" :key="i">
                              <td><div class="td-wrapper">
                                <div class="img-container">
                                  <img v-if="itemPart.info.product_type == 'image'" class="img" :src="$parent.imgDomain + itemPart.info.doc_url" />
                                  <div v-else class="doc-img">
                                    <img src='./../img/doc.svg' class="image"/>
                                  </div>
                                </div>
                              </div></td>
                              <td>
                                <div class="td-wrapper">
                                  {{itemPart.info.title}}
                                </div>
                              </td>
                              <td><div class="td-wrapper" v-if="categoryOptions && categoryOptions.length">
                                <span>{{$t(categoryOptions.find(obj => obj.id === itemPart.info.category_id).title)}}</span>
                              </div></td>
                              <td class="price"><div class="td-wrapper price">{{ itemPart.price }} {{$parent.currency}}</div></td>
                              <td>
                                <div class="td-wrapper td-wrapper-btns">
                                  <div class="options">
                                    <select v-if="resizeOptions" v-model="activeDownloadSize">
                                      <option v-for="(item,i) in resizeOptions" :key="i">{{ item }}</option>
                                    </select>
                                    <select v-if="formatOptions" v-model="activeDownloadFormat">
                                      <option v-for="(item,i) in formatOptions" :key="i">{{ item }}</option>
                                    </select>
                                  </div>
                                  <div class="button blue" @click="downloadProduct(itemPart.info)">
                                    {{$t('DOWNLOAD')}}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content" v-if="activeProfilePage === 'main'">
                    <div class="content-wrapper withdraw-content-wrapper main-content-wrapper">
                      <div class="table-container table-container-depositHistory">
                        <div class="table">
                          <table>
                            <thead>
                            <tr v-if="$parent.depositHistory && $parent.depositHistory.length">
                              <td>
                                <div class="td-wrapper">{{$t('Date')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Type')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Sum')}}</div>
                              </td>
                              <td>
                                <div class="td-wrapper">{{$t('Status')}}</div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td></td>
                            </tr>
                            </thead>
                            <tbody v-if="$parent.depositHistory && !$parent.depositHistory.length">
                            <tr>
                              <td class="bold">{{$t('Unfortunately, you don\'t have a purchase history yet.')}}</td>
                            </tr>
                            </tbody>
                            <tbody v-if="$parent.depositHistory && $parent.depositHistory.length">
                            <tr v-for="(item,i) in $parent.depositHistory" :key="i">
                              <td><div class="td-wrapper">{{$parent.formatDate(item.created_at)}}</div></td>
                              <td>
                                <div class="td-wrapper">
                                  {{item.type}}
                                </div>
                              </td>
                              <td class="price"><div class="td-wrapper price">{{item.sum}} {{item.currencyCode}}</div></td>
                              <td><div class="td-wrapper">
                  <span
                      :class="['status',
                    {'red': item.status === 'Error'},
                    {'green': item.status === 'Success'}
                    ]"
                  >{{item.status}}</span>
                              </div></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="upload-button button" @click="$parent.goToPromtCreate">
                      <img class="img" src="./../img/upload.svg"/>
                      <div class="desc">Загрузить файлы</div>
                    </div> -->
                  </div>
                  <div class="content" v-if="activeProfilePage === 'profile-edit'">
                    <div class="tab-list">
                      <div :class="['item title small', {'active': activeSettingsTab == item.page}]" v-for="item in settingsTabs" :key="item.page" @click="setSettingsActiveTab(item.page)">{{$t(item.title)}}</div>
                    </div>
                    <div class="content-wrapper">
                      <div class="table-container personal-info" v-if="activeSettingsTab === 'personal'">
                        <div class="table-container-wrapper">
                          <div class="title big">{{$t('Personal information')}}</div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Email')}}:</div>
                            <input type="email" :placeholder="$t('Email')" v-model="email"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> Phone:</div>
                            <input type="number" :placeholder="$t('Phone')" v-model="phone"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Nickname')}}:</div>
                            <input type="text" :placeholder="$t('Enter your nickname')" v-model="nickname"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Name')}}:</div>
                            <input type="text" :placeholder="$t('Enter your name')" v-model="name"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Surname')}}:</div>
                            <input type="text" :placeholder="$t('Enter your surname')" v-model="surname"/>
                          </div>
                          <div class="button-container">
                            <button class="button blue" @click="updateProfileData">
                              <span>{{$t('Update')}}</span>
                            </button>
                          </div>
                          <transition name="fade">
                            <div class="desc red" v-if="profileUpdateErrorMessaage">{{$t(profileUpdateErrorMessaage)}}</div>
                          </transition>
                          <transition name="fade">
                            <div class="desc green" v-if="profileUpdateSuccessMessaage">{{$t(profileUpdateSuccessMessaage)}}</div>
                          </transition>
                        </div>
                      </div>
                      <div class="table-container password" v-if="activeSettingsTab === 'password'">
                        <div class="table-container-wrapper">
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Old password')}}:</div>
                            <input type="text" :placeholder="$t('Enter your old password')" v-model="curPass"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('New password')}}:</div>
                            <input type="text" :placeholder="$t('Enter your new password')" v-model="newPass"/>
                          </div>
                          <div class="input-container">
                            <div class="desc"><span class="red">*</span> {{$t('Repeat new password')}}:</div>
                            <input type="text" :placeholder="$t('Repeat your new password')" v-model="repeatPass"/>
                          </div>
                          <button class="button blue" @click="updatePassword">
                            <span>{{$t('Update')}}</span>
                          </button>
                          <transition name="fade">
                            <div class="desc red" v-if="passUpdateErrorMessaage">{{$t(passUpdateErrorMessaage)}}</div>
                          </transition>
                          <transition name="fade">
                            <div class="desc green" v-if="passUpdateSuccessMessaage">{{$t(passUpdateSuccessMessaage)}}</div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
  import VTooltip from "v-tooltip";
  import Vue from 'vue'
  Vue.use(VTooltip);
export default {
  name: 'Profile',
  props: ['orderHistory', 'userName', 'createdHistory'],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      nickname: '',
      website: '',
      fb_url: '',
      ig_url: '',
      tt_url: '',
      joined: '',
      balance: '',
      avatar_path: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      withdrawName: '',
      withdrawEmail: '',
      profileNav: [
        {
          page: 'main',
          title: 'Payment history'
        },
        // {
        //   page: 'profile-edit',
        //   title: 'Edit profile'
        // },
        {
          page: 'my-poducts',
          title: 'My Work'
        },
        {
          page: 'purchased-poducts',
          title: 'Purchase history'
        }
      ],
      myProductsTabs: [
        {
          page: 'art',
          title: 'Art'
        },
        {
          page: 'text',
          title: 'Text'
        }
      ],
      settingsTabs: [
        {
          page: 'personal',
          title: 'Персональная информация'
        },
        {
          page: 'password',
          title: 'Смена пароля'
        }
      ],
      transactionTabs: [
        {
          page: 'deposits',
          title: 'Пополнение'
        },
        {
          page: 'sell',
          title: 'Продажа'
        }
      ],
      activeTransactionTab: 'deposits',
      activeMyProductsTab: 'Art',
      activeSettingsTab: 'personal',
      categoryOptions: [],
      profileIsEditable: false,
      createdHistoryImage: [],
      createdHistoryText: [],
      verification_data: '',
      userBalance: '',
      activeDownloadSize: '',
      activeDownloadFormat: '',
      resizeOptions: [],
      formatOptions: []
    }
  },
  
  watch: {
    userName: function () {
			this.getProfileData();
		},
    createdHistory: function (newArr) {
      this.createdHistoryImage = newArr.filter(item => item.doc_type == 'image');
      this.createdHistoryText = newArr.filter(item => item.doc_type == 'doc');
		}
  },
  
  mounted() {
    this.activeProfilePage = this.$route.params.page;
    this.getProfileData();
    this.getCategories();
    this.getDownloadOptions();
    this.createdHistoryImage = this.$parent.createdHistory.filter(item => item.doc_type == 'image');
    this.createdHistoryText = this.$parent.createdHistory.filter(item => item.doc_type == 'text');
    this.getOfferHistory();
  },
  methods: {
    openOffer(item) {
      this.$emit('openOffer', item)
    },
    getOfferHistory() {
      this.$http.get(process.env.VUE_APP_API + 'offer/history')
      .then((res) => {
          console.log(res);
          
      })
      .catch(() => {
       
      })
    },
    getDownloadOptions() {
      this.$http.get(process.env.VUE_APP_API + 'download-options')
      .then((res) => {
        this.resizeOptions = res.data.payload.resize_options;
        this.formatOptions = res.data.payload.format_options;
        this.activeDownloadSize = res.data.payload.resize_options[0];
        this.activeDownloadFormat = res.data.payload.format_options[0];
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    deletePrompt(id) {
      this.$emit('deactivateCreatedItem', id);
    },
    downloadProduct(product) {
      this.$http.get(process.env.VUE_APP_API + 'user/media/download?id=' + product.id + '&size=' + this.activeDownloadSize + '&format=' + this.activeDownloadFormat)
      .then((res) => {
        window.location = res.config.url;
      })
      .catch((res) => {
        this.$emit('setNewError', res.response.data.message)
      })
    },
    editProfile() {
      if (this.profileIsEditable === true) {
        this.updateProfileData();
        this.profileIsEditable = false;
      } else {
        this.profileIsEditable = true;
      }
      
    },
    getFilenameFromContentDisposition(contentDisposition) {
      const match = /filename\*=UTF-8''([^;]*)/.exec(contentDisposition) || /filename="([^"]*)"/.exec(contentDisposition);
      if (match && match[1]) {
        return decodeURIComponent(match[1]);
      } else {
        return 'downloaded-file';
      }
    },
    saveFile(blob, filename) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname,
        // "nickname": this.nickname,
        // "web_url": this.website,
        // "fb_url": this.fb_url,
        // "ig_url": this.ig_url,
        // "tt_url": this.tt_url
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    },
    setActiveMyProductsTab(page) {
      this.activeMyProductsTab = page;
    },
    setActiveTransactionTab(page) {
      this.activeTransactionTab = page;
    },
    setSettingsActiveTab(page) {
      this.activeSettingsTab = page;
    },
    getProfileData() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.joined = res.data.created_at;
          this.balance = res.data.balance;
          this.nickname = res.data.nickname;
          this.website = res.data.web_url;
          this.fb_url = res.data.fb_url;
          this.ig_url = res.data.ig_url;
          this.tt_url = res.data.tt_url;
          this.avatar_path = res.data.avatar_path;
          this.verification_data = res.data.verification_data;
          this.userBalance = res.data.balance;
          
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    editPrompt(item) {
      this.$emit('editPrompt', item);
    }
  }
}
</script>
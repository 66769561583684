<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay" @click="$emit('closeDealModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeDealModal')" src="./../img/close.svg" />
      <div class="container">
        <div class="title">{{ $t('Deal is completed') }}</div>
        <div class="desc">
          {{ $t('You will receive your funds within 5 business days') }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'DealModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>
<template>
	<div class="item-wrapper" v-if="item">
		<div class="top" @click="goToSliderItem(item)">
			<div class="preview-container">
				<div class="preview" v-if="lazy">
					<img v-if="item.product_type == 'image'" :data-lazy="imgDomain + item.doc_url" class="img"/>
					<div v-else class="doc-img">
						<img src='./../img/doc.svg' class="img"/>
						<div class="desc" v-html="item.description"></div>
					</div>
				</div>
				<div class="preview" v-else>
					<img v-if="item.product_type == 'image'" :src="imgDomain + item.doc_url" class="img"/>
					<div v-else class="doc-img">
						<img src='./../img/doc.svg' class="img"/>
						<div class="desc" v-html="item.description"></div>
					</div>
				</div>
			</div>
		</div>
		<div :class="['bottom', {'bottom-doc': item.product_type !== 'image'}]" @click="goToSliderItem(item)">
			<div class="title">{{item.title}}</div>
			<div class="buy">
				<div class="price title">
					<div>Цена:</div>
					<span>{{item.price}} <span class="currency">{{currency}}</span></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'sliderIsClickble', 'currency', 'isAuth', 'lazy', 'categories'],
	data: function() {
		return {
			imgDomain: '',
			tag: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		openReadyModal() {
			this.$emit('openReadyModal');
		},
		isNumber(variable) {
			return Number.isFinite(variable) && typeof variable === "number";
		},
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
		getPack() {
			if (this.isAuth) {
				this.openReadyModal();
			} else {
				this.openSignInModal();
			}
		},
		openSignInModal() {
			this.$emit('openSignInModal');
		},
		openBuyModal(item, type) {
			this.$emit('openBuyModal', item, type)
		}
	},
}
</script>

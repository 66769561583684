<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay"  @click="$parent.closeBuySuccessModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeBuySuccessModal" src="./../img/close.svg"/>
      <div class="container">
        <div class="title">{{$t("You've successfully purchased product.")}}</div>
        <div class="desc">
          {{$t("Please check Purchase History to view and download product")}}
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'SuccessBuyModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>
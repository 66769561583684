<template>
  <main class="main page-inside verification-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <ValidationObserver v-if="!submitSuccess" v-slot="{ invalid, pristine, errors, handleSubmit }" slim>
          <form @submit.prevent="handleSubmit(submit)" class="wrapper">
            <div class="title">{{$t('ID Verification')}}</div>

            <div class="title small" v-if="profileData && profileData.verification_data.status === 'approved'">{{$t('Your profile verification approved')}}</div>
            <div class="title small" v-else-if="profileData && profileData.verification_data.status === 'pending'">{{$t('Your profile verification is in progress')}}</div>
            <div class="title small" v-else>{{$t('Please confirm your profile to withdraw funds')}}</div>

            <div class="list">
              <div class="item">
                <div class="item-wrapper">
                  <!-- <div class="num desc">1</div> -->
                  <ValidationProvider name="fullName" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="fullName">{{$t('Name')}}:</label>
                      <input id="fullName"
                             :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                             type="text" :placeholder="$t('Full Name')" :class="{ error: invalid && dirty }" v-model="fullName" />
                    </div>
                  </ValidationProvider>
                  <!-- <ValidationProvider name="email" v-slot="{ invalid, dirty }" rules="required|email" slim> -->
                  <div class="input-container">
                    <label for="email">{{$t('Email')}}:</label>
                    <input id="email"
                           readonly
                           tabindex="-1"
                           type="email" :value="profileData.email"/>
                  </div>
                  <!-- </ValidationProvider> -->
                  <!-- <ValidationProvider name="phone" v-slot="{ invalid, dirty }" rules="required" slim> -->
                  <div class="input-container">
                    <label for="phone">{{$t('Phone number')}}:</label>
                    <input id="phone"
                           readonly
                           tabindex="-1"
                           type="number" :value="profileData.phone" />
                  </div>
                  <!-- </ValidationProvider> -->
                  <div class="input-container">
                    <label for="date">{{$t('Date of birth')}}:</label>
                    <!-- <div class="half-width"> -->
                    <input id="date"
                           :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                           type="date" :placeholder="$t('Date of birth')" v-model="date" />
                    <!-- </div> -->
                  </div>
                  <ValidationProvider name="nationality" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="nationality">{{$t('Nationality')}}:</label>
                      <select id="nationality"
                              class = "document-select"
                              :disabled="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                              :class="{ error: invalid && dirty }" v-model="nationality">
                        <option selected hidden value=""></option>
                        <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.title }}</option>
                      </select>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="item-separator"></div>

                <div class="item-wrapper">
                  <!-- <div class="num desc">2</div> -->
                  <ValidationProvider name="address" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="address">{{$t('Address Line')}}:</label>
                      <input id="address"
                             :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                             type="text" :placeholder="$t('Address Line')" :class="{ error: invalid && dirty }" v-model="address" />
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="city" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="city">{{$t('City')}}:</label>
                      <input id="city"
                             :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                             type="text" :placeholder="$t('City')" :class="{ error: invalid && dirty }" v-model="city" />
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="state" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="state">{{$t('State')}}:</label>
                      <input id="state"
                             :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                             type="text" :placeholder="$t('State')" :class="{ error: invalid && dirty }" v-model="state" />
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="country" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="country">{{$t('Country')}}:</label>
                      <select id="country"
                              class = "document-select"
                              :disabled="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                              :class="{ error: invalid && dirty }" v-model="country">
                        <option disabled selected hidden value=""></option>
                        <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.title }}</option>
                      </select>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="zip" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="zip">{{$t('Zip Code')}}:</label>
                      <input id="zip"
                             :readonly="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                             type="text" :placeholder="$t('Zip Code')" :class="{ error: invalid && dirty }" v-model="zip" />
                    </div>
                  </ValidationProvider>
                </div>
              </div>


              <div class="item">
                <div class="title small">{{$t('To verify your identity, please upload any of your document')}}</div>
                <div class="item-wrapper">
                  <!-- <div class="num desc">3</div> -->
                  <ValidationProvider name="identityDocType" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="type-of-document">{{$t('Select type of document')}}:</label>
                      <select
                          class = "document-select"
                          :disabled="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                          v-model="identityDocType" :class="{ error: invalid && dirty }"
                          @change="handleDocTypeChange('identity', $event.target.value)">
                        <option disabled selected hidden value=""></option>
                        <option v-for="(type) in identityDocsTypes" :value="type" :key="type">
                          {{ type.charAt(0).toUpperCase() + type.slice(1) }}
                        </option>
                      </select>
                    </div>
                  </ValidationProvider>
                  <div class="doc-labels" v-for="([field, value], i) in identityDocs" :key="i">
                    <span class="desc-title">{{ i % 2 === 0 ? $t('Front Side') : $t('Back Side') }}</span>
                    <label :class="['img-label', { 'active': value && value.image }]">
                      <div class="image-container">
                        <input type="file" :key="inputKeys[field]" :ref="'fileInput' + field" @change="setImage('identity', field, $event)" />
                        <img v-if="value && value.image" :src='value.image' class="img" />
                        <div v-if="value && value.image && value.imageFile" class="desc desc-inside">
                          <div class="desc">{{ value.imageFile.name }}</div>
                        </div>
                        <img v-if="!value || !value.image" src="./../img/document_front_back.svg" class="img" />
                        <div v-if="!value || !value.image" class="desc desc-inside">
                          <div class="button">
                            <span>{{$t('Click or drag file to this area to upload')}}</span>
                          </div>
                        </div>
                      </div>
                      <button v-if="value && value.image" @click.stop.prevent="deleteImage('identity', field, $event)" class="delete-button"><img src="./../img/delete_icon.svg" /></button>
                    </label>
                  </div>



                  <img v-if="document_scan1" class="document-img" :src="imgDomain + document_scan1" />
                  <img v-if="document_scan2" class="document-img" :src="imgDomain + document_scan2" />
                  <!--                <label :class="['img-label', {'active': image}]">-->
                  <!--                  <input type="file" @change="setImage" />-->
                  <!--                  <img v-if="image"  :src='image' class="img"/>-->
                  <!--                  <div v-if="image && imageFile" class="desc desc-inside">-->
                  <!--                    <div class="title small">Your file</div>-->
                  <!--                    <div class="desc">{{imageFile.name}}</div>-->
                  <!--                  </div>-->
                  <!--                  <img v-if="!image" src="./../img/doc2.svg" class="img"/>-->
                  <!--                  <div v-if="!image" class="desc desc-inside">-->
                  <!--                    <div class="title small">Back side</div>-->
                  <!--                    <div class="button">-->
                  <!--                      <img src="./../img/down2.svg" class="img"/>-->
                  <!--                      <span>Upload</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </label>-->
                </div>
              </div>
              <div class="item">
                <div class="title small">{{$t('To verify your Address, please upload any of your document')}}</div>
                <div class="item-wrapper">
                  <!-- <div class="num desc">4</div> -->
                  <ValidationProvider name="addressDocType" v-slot="{ invalid, dirty }" rules="required" slim>
                    <div class="input-container">
                      <label for="type-of-document">{{$t('Select type of document')}}:</label>
                      <select
                          class = "document-select"
                          :disabled="profileData.verification_data.status === 'approved' || profileData.verification_data.status === 'pending'"
                          v-model="addressDocType" :class="{ error: invalid && dirty }"
                          @change="handleDocTypeChange('address', $event.target.value)">
                        <option disabled selected hidden value=""></option>
                        <option v-for="(type) in addressDocsTypes" :value="type" :key="type">{{$t(displayAddressDoc(type))}}
                        </option>
                      </select>
                    </div>
                  </ValidationProvider>
                  <span v-if="addressDocs.length > 0" class="desc-title">{{$t('Add your file')}}:</span>
                  <label v-for="([field, value], i) in addressDocs" :key="i" :class="['img-label', { 'active': value && value.image }]">
                    <div class="image-container">
                      <input type="file" :key="inputKeys[field]" @change="setImage('address', field, $event)" />
                      <img v-if="value && value.image" :src='value.image' class="img" />
                      <div v-if="value && value.image && value.imageFile" class="desc desc-inside">
                        <!-- <div class="title small">Your file</div> -->
                        <div class="desc">{{ value.imageFile.name }}</div>
                      </div>
                      <img v-if="!value || !value.image" src="./../img/document_front_back.svg" class="img" />
                      <div v-if="!value || !value.image" class="desc desc-inside">
                        <!-- <div class="title small">{{ fileDescription(addressDocuments, i) }}</div> -->
                        <div class="button">
                          <!-- <img src="./../img/down2.svg" class="img"/> -->
                          <span>{{$t('Click or drag file to this area to upload')}}</span>
                        </div>
                      </div>
                    </div>
                    <button v-if="value && value.image" @click.stop.prevent="deleteImage('address', field, $event)" class="delete-button"><img src="./../img/delete_icon.svg" /></button>
                  </label>
                  <img v-if="address_document_scan" class="document-img" :src="imgDomain + address_document_scan" />
                </div>
              </div>
            </div>
            <div id="verification-page-footer"
                 v-if="profileData.verification_data.status !== 'approved' && profileData.verification_data.status !== 'pending'">
              <ValidationProvider name="infoCorrect" rules="required" slim>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="infoCorrect" />
                      <div class="checkbox"></div>
                      <span class="title">{{$t('All the personal information i have entered is correct.')}}</span>
                    </div>
                  </label>
                </div>
              </ValidationProvider>
              <ValidationProvider name="terms" rules="required" slim>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms" />
                      <div class="checkbox"></div>
                      <span class="title">{{$t('I accept')}} </span>
                      <a @click="$parent.goToPage('terms')" class="title">{{$t('Terms of Use')}}</a>
                      <span class="title">{{$t('and')}}</span>
                      <a @click="$parent.goToPage('privacy')" class="title">{{$t('Privacy policy')}}</a>
                    </div>
                  </label>
                </div>
              </ValidationProvider>
              <button type="submit" :disabled="invalid" class="button blue">{{$t('Verify my profile')}}</button>
              <div class="desc">{{$t('Your application will be reviewed within 1-2 business days')}}</div>
            </div>
          </form>
        </ValidationObserver>
        <div v-else class="submit-success title">
          {{$t('Your application has been successfully sent!')}}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { serialize } from "object-to-formdata";

export default {
  name: 'Verification',
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: function () {
    return {
      imgDomain: '',
      submitSuccess: false,
      countries: [],
      fullName: '',
      email: '',
      phone: '',
      date: '',
      nationality: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      documentTypeOptions: [],
      identityDocType: '',
      addressDocType: '',
      identityDocuments: {},
      addressDocuments: {},
      image: '',
      imageFile: '',
      terms: null,
      infoCorrect: null,
      identityDocFiles: [],
      addressDocFiles: [],
      profileData: '',
      document_scan1: '',
      document_scan2: '',
      address_document_scan: '',
      datePlaceholder: 'Date of birth (DD/MM/YYYY)',
      inputKeys: {},
    }
  },
  mounted: function () {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
        this.countries = res.data.payload;
      })
      .catch(() => {

      })
    this.$http.get(process.env.VUE_APP_API + 'doc-types')
      .then((res) => {
        this.documentTypeOptions = res.data.payload;
      })
      .catch(() => {

      })
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.profileData = res.data;
        this.fullName = res.data.verification_data.name;
        this.email = res.data.verification_data.email;
        this.phone = res.data.verification_data.phone;
        if (res.data.verification_data.birth_date) {
          this.date = res.data.verification_data.birth_date;
        }
        if (res.data.verification_data.nationality_id) {
          this.nationality = res.data.verification_data.nationality_id;
        }
        this.address = res.data.verification_data.address;
        this.city = res.data.verification_data.city;
        this.state = res.data.verification_data.state;
        if (res.data.verification_data.country_id) {
          this.country = res.data.verification_data.country_id;
        }
        this.zip = res.data.verification_data.zip_code;
        if (res.data.verification_data.document_type) {
          this.identityDocType = res.data.verification_data.document_type;
        }
        if (res.data.verification_data.address_doc_type) {
          this.addressDocType = res.data.verification_data.address_doc_type;
        }

        if (res.data.verification_data.document_scan1) {
          this.document_scan1 = res.data.verification_data.document_scan1;
        }
        if (res.data.verification_data.document_scan2) {
          this.document_scan2 = res.data.verification_data.document_scan2;
        }
        if (res.data.verification_data.address_document_scan) {
          this.address_document_scan = res.data.verification_data.address_document_scan;
        }




      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
  },
  watch: {
    identityDocType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearDocuments('identity');
      }
    },
    addressDocType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearDocuments('address');
      }
    }
  },
  methods: {
    async setImage(docType, doc, event) {
      const that = this;
      const propName = `${docType}Documents`;
      const documents = { ...this[propName] };
      const document = documents[doc];
      const selectedFile = event.target.files[0];
      document.imageFile = selectedFile;

      if (selectedFile) {
        document.image = URL.createObjectURL(selectedFile);
      } else {
        document.image = null;
      }

      documents[doc] = document;
      Object.assign(that, { [propName]: documents })
      console.log(that[propName]);
    },
    deleteImage(docType, doc) {
      const documents = { ...this[`${docType}Documents`] };
      const document = documents[doc];
      document.image = null;
      document.imageFile = null;
      documents[doc] = document;
      this[`${docType}Documents`] = documents;
      this.inputKeys[doc] = (this.inputKeys[doc] || 0) + 1; // Increment the unique key for the file input
    },
    clearDocuments(docType) {
      const documents = { ...this[`${docType}Documents`] };
      for (let doc in documents) {
        documents[doc].image = null;
        documents[doc].imageFile = null;
        this.inputKeys[doc] = (this.inputKeys[doc] || 0) + 1; // Increment the unique key for the file input
      }
      this[`${docType}Documents`] = documents;
    },
    displayAddressDoc(doc) {
      doc = doc.replace(/_/g, ' ');
      return doc.charAt(0).toUpperCase() + doc.slice(1);
    },
    handleDocTypeChange(type, value) {
      let docTypes;
      if (type === 'identity') {
        docTypes = this.documentTypeOptions.identity_docs[value];
      } else if (type === 'address') {
        docTypes = this.documentTypeOptions.address_docs[value];
      }

      const documents = Object.fromEntries(docTypes.map((value) => ([value, {}])));

      if (type === 'identity') {
        this.identityDocuments = documents;
      } else if (type === 'address') {
        this.addressDocuments = documents;
      }
    },
    fileDescription(docs, i) {
      const docsLength = Object.keys(docs).length;

      if (docsLength === 1 || docsLength > 2) {
        return 'Add your file';
      } else if (i === 0) {
        return 'Front side';
      } else {
        return 'Back side';
      }
    },
    async submit() {
      const data = serialize({
        name: this.fullName,
        birth_date: this.date,
        nationality_id: this.nationality,
        address: this.address,
        city: this.city,
        state: this.state,
        country_id: this.country,
        zip_code: this.zip,
        document_type: this.identityDocType,
        address_doc_type: this.addressDocType
      });

      Object.entries(this.identityDocuments).forEach(([name, { imageFile }]) => {
        data.append(name, imageFile);
      });

      Object.entries(this.addressDocuments).forEach(([name, { imageFile }]) => {
        data.append(name, imageFile);
      })

      this.$http.post(process.env.VUE_APP_API + 'user/verification', data, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.status === 'OK') {
          this.submitSuccess = true;
        }
      }).catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
              firstErrors[key] = res.response.data.errors[key][0];
              this.$emit('setNewError', firstErrors[key]);
            }
          }
        } else {
          this.$emit('setNewError', res.response.data.message);
        }
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      })
    }
  },
  computed: {
    identityDocsTypes() {
      return this.documentTypeOptions.identity_docs ? Object.keys(this.documentTypeOptions.identity_docs) : [];
    },
    addressDocsTypes() {
      return this.documentTypeOptions.address_docs ? Object.keys(this.documentTypeOptions.address_docs) : [];
    },
    identityDocs() {
      return Object.entries(this.identityDocuments);
    },
    addressDocs() {
      return Object.entries(this.addressDocuments);
    }
    // docs() {
    //   const that = this;
    //   return type => {
    //     that.
    //   }
    // }
  }
}
</script>
<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay" @click="$parent.closeNoDealModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeNoDealModal" src="../img/close.svg"/>
      <div class="container">
        <div class="title">{{$t('Deal is declined')}}</div>
        <div class="desc">{{$t('Try again or contact us')}}</div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'NoDealModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>
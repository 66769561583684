<template>
  <main class="main login-page">
    <div class="main-wrapper">
      <div class="container">
        <div class="left">
          <div class="left-up">
            <div @click="$parent.closeSignInModal" class="desc back">
              {{$t('Back to mainpage')}}
            </div>
            <div class="form">
              <div class="form-wrapper">
                <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
                  <div class="form-fields-wrapper">
                    <div class="title medium">{{$t('Login')}}</div>
                    <label>
                      <div class="desc">{{$t('Email')}}:</div>
                      <input v-on:keyup.enter="submitLogin" type="email" :placeholder="$t('Email')" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc">{{$t('Password')}}:</div>
                      <input v-on:keyup.enter="submitLogin" type="password" :placeholder="$t('Password')" v-model="pass"/>
                    </label>
                    <div class="link" @click="switchToforgotPass">{{$t('Forgot password?')}}</div>
                    <div class="cta-container">
                      <button class="button blue" @click="submitLogin">
                        <span>{{$t('Login')}}</span>
                      </button>
                    </div>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
                <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
                  <div class="form-fields-wrapper">
                    <div class="title medium">{{$t('Forgot password?')}}</div>
                    <label>
                      <div class="desc">{{$t('Email')}}</div>
                      <input type="email" :placeholder="$t('Email')" v-model="email"/>
                    </label>
                    <div class="cta-container">
                      <button class="button blue" @click="submitForgotPass">
                        <span>{{$t('Recover')}}</span>
                      </button>
                    </div>
                    <transition name="fade">
                      <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                    </transition>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-down">
            <div class="modal-bottom" v-if="forgotPassContentVisible">
              <div class="desc">{{$t('Already have an account?')}}</div>
              <div class="link switch-login" @click="switchToLogin()">{{$t('Log in with existing account')}}</div>
            </div>
            <div class="modal-bottom" v-if="!forgotPassContentVisible">
              <div class="desc">{{$t("Don't have an account?")}}</div>
              <div class="link switch-register" @click="$parent.openSignUpModal">{{$t('Sign up for FREE')}}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="./../img/login.png" class="img"/>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  
  export default {
    name: 'Login',
    props: [],
    components: {
    },
    data: function() {
      return {
        loginContentVisible: true,
        forgotPassContentVisible: false,
        email: '',
        pass: ''
      }
    },
    mounted() {
      
    },
    methods: {
      
      switchToforgotPass() {
        this.$parent.clearError();
        setTimeout(()=> {
          this.loginContentVisible = false;
          this.forgotPassContentVisible = true;
        }, 100)
      },
      switchToLogin() {
        this.$parent.clearError();
        setTimeout(()=> {
          this.forgotPassContentVisible = false;
          this.loginContentVisible = true;
        }, 100)
      },
      submitLogin() {
        let loginData = {
          "username": this.email,
          "password": this.pass
        }
        this.$emit('login', loginData)
      },
      submitForgotPass() {
        let data = {
          "email": this.email
        }
        this.$emit('forgotPass', data)
      }
    }
  }
  </script>
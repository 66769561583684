<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{$t('Buy Sell')}}<span>{{$t('Repeat')}}</span></div>
          <div class="desc big">
            {{$t('Welcome to the ArtAiBuy,  the ultimate destination for buying and selling art!')}}
          </div>
          <div class="down">
            <div class="desc">{{$t('Join us and unleash the power of collaboration, innovation, and')}}</div>
            <div class="title">{{$t('imagination')}}</div>
            <img src="./../img/brush.svg" class="img"/>
          </div>
        </div>
      </div>

      <div class="section how-it-works-section">
        <div class="wrapper">
          <div class="left">
            <div class="title medium">{{$t('How it works?')}}</div>
          </div>
          <div class="right">
            <img src="./../img/lines.svg" class="img"/>
            <div class="right-wrapper">
              <div class="desc">
                {{$t('We are inspired by the ability to buy and sell ready to use AI files, with the ease of just a few clicks.')}}
                <br/><br/>
                {{$t('And we want to give this opportunity to every user.')}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section welcome-section">
        <div class="wrapper">
          <div class="desc big">
            {{$t('Welcome to the collaborative future of content creation - right at your fingertips.')}}
          </div>
        </div>
      </div>

      <div class="section slider-section">
        <div class="wrapper">
          <div class="title" v-if="typeOptions[0]">
            {{$t(typeOptions[0].title)}}
          </div>
          <div class="carousel">
            <SlickItem :categories="categories" v-if="productList1 && productList1.length" :list="productList1" @goToProduct="goToProduct" :currency="currency" />
          </div>
        </div>
      </div>

      <div class="section slider-section">
        <div class="wrapper">
          <div class="title" v-if="typeOptions[1]">
            {{$t(typeOptions[1].title)}}
          </div>
          <div class="carousel">
            <SlickItem :categories="categories" v-if="productList2 && productList2.length" :list="productList2" @goToProduct="goToProduct" :currency="currency" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SlickItem from '../components/SlickItem.vue';


export default {
  name: 'Home',
  props: ['currency'],
  components: {
    SlickItem
  },
  data: function() {
    return {
      imgDomain: '',
      categories: [],
      productList1: [],
      productList2: [],
      typeOptions: ''
    }
  },
  mounted() {
    this.getProducts();
    this.getProducts2();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'categories')
    .then((res) => {
      this.categories = res.data.payload;
    })
    .catch(() => {
        
    })
    this.$http.get(process.env.VUE_APP_API + 'categories')
    .then((res) => {
      this.typeOptions = res.data.payload.filter(item => item.parent_id === null);
    })
    .catch(() => {
        
    })
  },
  methods: {
    
    getProducts() {
      this.productList1 = [];

      this.$http.get(process.env.VUE_APP_API + 'products/favorites')
      .then((resp) => {
        this.productList1 = resp.data.payload.filter(item => (item.product_type === 'image'));
      })
      .catch(() => {
        
      })
      
			
    },
    getProducts2() {
      this.productList2 = [];

      this.$http.get(process.env.VUE_APP_API + 'products/favorites')
      .then((resp) => {
        this.productList2 = resp.data.payload.filter(item => (item.product_type === 'doc'));
      })
      .catch(() => {
        
      })
			
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$parent.openReadyModal();
			this.$parent.setReadyPack();
		},
  
  }
}
</script>